@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&family=Herr+Von+Muellerhoff&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ballet:opsz@16..72&family=Cinzel:wght@400..900&family=Herr+Von+Muellerhoff&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");

// -----------------------------
// D E F I N E  F O N T  V A R I A B L E S, S I Z E S, L I N E  H E I G H T S, E T C:

// Font Families
$font-cinzel: "Cinzel", serif;
$font-herr: "Herr Von Muellerhoff", cursive;
$font-nunito: "Nunito Sans", sans-serif;

// Font Weights
$font-weight-regular: 400;
$font-weight-extralight: 200;
$font-weight-semibold: 600;

// Line Heights
$line-height-normal: normal;

// Mobile Font Styles
$mc-home-decorative-size: 1.9rem;
$mh-home-decorative-size: 4.625rem;
$mc-h2-size: 2rem;
$mh-h2-size: 4.2rem;
$mc-h3-size: 1.0625rem;
$mh-h3-size: 2.125rem;
$mc-h4-size: 1.125rem;
$m-body-size: 0.875rem;
$m-body-line-height: 1.42363rem;
$m-caption-extra-light-size: 0.625rem;
$m-caption-extra-light-line-height: 1.01688rem;
$m-caption-regular-size: 0.625rem;
$m-caption-regular-line-height: 1.01688rem;
$m-navigation-size: 0.5625rem;
$m-navigation-letter-spacing: 0.01125rem;
$m-navigation-text-transform: uppercase;

// Tablet Font Styles
$tc-home-decorative-size: 4rem;
$th-home-decorative-size: 5.625rem;
$tc-h2-size: 2.8125rem;
$th-h2-size: 5.9375rem;
$tc-h3-size: 1.875rem;
$th-h3-size: 3.125rem;
$tc-h4-size: 1.125rem;
$tc-h5-size: 1rem;
$t-body-size: 1rem;
$t-body-line-height: 1.627rem;
$t-caption-extra-light-size: 0.625rem;
$t-caption-regular-size: 0.625rem;

// Desktop Font Styles
$dc-home-decorative-size: 4.25rem;
$dh-home-decorative-size: 8.125rem;
$dc-h2-size: 2.9375rem;
$dh-h2-size: 6.0625rem;
$dc-h3-size: 2.125rem;
$dc-h4-size: 1.25rem;
$dc-h5-size: 1.125rem;
$d-body-size: 1.125rem;
$d-body-line-height: 1.83038rem;
$d-caption-extra-light-size: 0.625rem;
$d-caption-regular-size: 0.625rem;

// -----------------------------
// B A S E  S T Y L E S (Mobile)
body {
  font-family: $font-nunito;
}

.m-cinzel-home-decorative {
  font-family: $font-cinzel;
  font-weight: $font-weight-regular;
  font-size: $mc-home-decorative-size;
  line-height: $line-height-normal;
  text-transform: lowercase;
}

.m-herr-home-decorative {
  font-family: $font-herr;
  font-weight: $font-weight-regular;
  font-size: $mh-home-decorative-size;
  line-height: $line-height-normal;
  text-transform: capitalize;
}

.m-cinzel-h2 {
  font-family: $font-cinzel;
  font-weight: $font-weight-regular;
  font-size: $mc-h2-size;
  line-height: $line-height-normal;
  text-transform: lowercase;
}

.m-herr-h2 {
  font-family: $font-herr;
  font-weight: $font-weight-regular;
  font-size: $mh-h2-size;
  line-height: $line-height-normal;
  text-transform: capitalize;
}

.m-cinzel-h3 {
  font-family: $font-cinzel;
  font-weight: $font-weight-regular;
  font-size: $mc-h3-size;
  line-height: $line-height-normal;
  text-transform: lowercase;
}

.m-herr-h3 {
  font-family: $font-herr;
  font-weight: $font-weight-regular;
  font-size: $mh-h3-size;
  line-height: $line-height-normal;
  text-transform: capitalize;
}

.m-cinzel-h4 {
  font-family: $font-cinzel;
  font-weight: $font-weight-regular;
  font-size: $mc-h4-size;
  line-height: 1.5rem;
  text-transform: lowercase;
}

.m-body {
  font-family: $font-nunito;
  font-weight: $font-weight-regular;
  font-size: $m-body-size;
  line-height: $m-body-line-height;
  letter-spacing: -0.00875rem;
}

.m-caption-extra-light {
  font-family: $font-nunito;
  font-weight: $font-weight-extralight;
  font-size: $m-caption-extra-light-size;
  line-height: $m-caption-extra-light-line-height;
}

.m-caption-regular {
  font-family: $font-nunito;
  font-weight: $font-weight-regular;
  font-size: $m-caption-regular-size;
  line-height: $m-caption-regular-line-height;
}

.m-navigation {
  font-family: $font-nunito;
  font-weight: $font-weight-semibold;
  font-size: $m-navigation-size;
  line-height: $line-height-normal;
  letter-spacing: $m-navigation-letter-spacing;
  text-transform: $m-navigation-text-transform;
}

.testimonial-background {
  font-family: $font-herr;
  opacity: 0.15;
  font-size: 10.875rem; // Mobile size
}



// -----------------------------
// T A B L E T  S T Y L E S
@media (min-width: 768px) {
  .m-cinzel-home-decorative {
    font-size: $tc-home-decorative-size;
    line-height: 4.9375rem;
  }

  .m-herr-home-decorative {
    font-size: $th-home-decorative-size;
    line-height: $line-height-normal;
  }

  .m-cinzel-h2 {
    font-size: $tc-h2-size;
    line-height: 2.8125rem;
  }

  .m-herr-h2 {
    font-size: $th-h2-size;
    line-height: 2.8125rem;
  }

  .m-cinzel-h3 {
    font-size: $tc-h3-size;
    line-height: 2.25rem;
  }

  .m-herr-h3 {
    font-size: $th-h3-size;
    line-height: $line-height-normal;
  }

  .m-cinzel-h4 {
    font-size: $tc-h4-size;
    line-height: 1.5rem;
  }

  .m-cinzel-h5 {
    font-size: $tc-h5-size;
    line-height: $line-height-normal;
    // text-transform: lowercase;
  }

  .m-body {
    font-size: $t-body-size;
    line-height: $t-body-line-height;
  }

  .m-caption-extra-light {
    font-size: $t-caption-extra-light-size;
  }

  .m-caption-regular {
    font-size: $t-caption-regular-size;
  }

  .m-navigation {
    font-size: $m-navigation-size;
    letter-spacing: $m-navigation-letter-spacing;
    text-transform: $m-navigation-text-transform;
  }

  .testimonial-background {
    font-size: 16.25rem; // Tablet size
  }
}

// -----------------------------
// D E S K T O P  S T Y L E S
@media (min-width: 1024px) {
  .m-cinzel-home-decorative {
    font-size: $dc-home-decorative-size;
    line-height: 5.25rem;
  }

  .m-herr-home-decorative {
    font-size: $dh-home-decorative-size;
    line-height: $line-height-normal;
  }

  .m-cinzel-h2 {
    font-size: $dc-h2-size;
    line-height: 2.8125rem;
  }

  .m-herr-h2 {
    font-size: $dh-h2-size;
    line-height: 2.8125rem;
  }

  .m-cinzel-h3 {
    font-size: $dc-h3-size;
    line-height: 2.25rem;
  }

  .m-cinzel-h4 {
    font-size: $dc-h4-size;
    line-height: 1.5rem;
  }

  .m-cinzel-h5 {
    font-size: $dc-h5-size;
    line-height: $line-height-normal;
    // text-transform: lowercase;
  }

  .m-body {
    font-size: $d-body-size;
    line-height: $d-body-line-height;
  }

  .m-caption-extra-light {
    font-size: $d-caption-extra-light-size;
  }

  .m-caption-regular {
    font-size: $d-caption-regular-size;
  }

  .m-navigation {
    font-size: $m-navigation-size;
    letter-spacing: $m-navigation-letter-spacing;
    text-transform: $m-navigation-text-transform;
  }

  .testimonial-background {
    font-size: 17.5rem; // Desktop size
  }
}
