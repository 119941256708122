@import "./GlobalColors.scss";

@media (min-width: 0px) and (max-width: 767px) {
  .SideNav {
    display: none;
  }
}

// -----------------------------
// T A B L E T  S T Y L E S
@media (min-width: 768px) {
  .SideNav {
    position: fixed; /* Keeps the nav in a fixed position */
    top: 0;
    left: 0;
    width: 15.6rem;
    height: 100vh; /* Ensures it covers the entire viewport height */
    background-color: $color-black;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1000;
  }

  #SideNav {
    width: 15.6rem;
    background-image: none;
    justify-content: center;
    padding: 2.25rem 1.5rem !important;
  }

  #SideNav > .section-inner {
    justify-content: space-between;
    max-height: 70rem;
  }

  .SideNavTopContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    .SideNavMiddleContent .SideNavUl {
      padding: 0;
      list-style-type: none;
      flex-direction: column;
      min-width: unset;
      height: 25.4rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .sideNavMyName {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0rem;
  }

  .SideNavBottomContent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .SideNavBottomContent .footer-icons {
    display: flex;
    width: 8rem;
    justify-content: space-between;
    a {
      display: flex;
      // border: 1px solid white;
      height: 2rem;
      width: 2rem;
      align-items: center;
      justify-content: center;
      padding: 1.5rem;
      border-radius: 50%;
      transition: 0.15s ease-in-out;
      cursor: pointer;
    }
  }

  .footer-icons a:hover {
    background-color: $color-hotpink;
    box-shadow: 1px 1px 10px 0px darkgray;

  }
}

// -----------------------------
// D E S K T O P  S T Y L E S
@media (min-width: 1024px) {
}

//  TESTING THIS:

.nav-item {
  cursor: pointer;
  transition: color 0.3s;

  &.active {
    color: pink; // Change this to the highlight color you prefer
    text-shadow: 0px 0px 7px darkgray;
  }
}
.SideNavUl li:hover {
  color: pink;
}
.sideNavMyName:hover {
  box-shadow: 0px 0px 5px 0px pink;
}

.sideNavMyName {
  border: 1px solid pink;
  height: 8rem;
  width: 8rem;
  /* align-items: center; */
  justify-content: center;
  padding: 1.5rem;
  border-radius: 50%;
  /* box-shadow: 1px 1px 10px 0px darkgray; */
  transition: 0.15s ease-in-out;
  cursor: pointer;
  .m-cinzel-h3 {
    font-size: 1.4rem;
    color: pink;
  }
}

// .SideNav {
//   overflow: scroll !important;
//   scrollbar-gutter: stable;
// }

// -----------------------------
// T A B L E T  S T Y L E S
@media (min-width: 768px) {
  .SideNav {
    position: fixed;
    top: 0;
    left: 0;
    width: 15.6rem;
    height: 100vh;
    background-color: $color-black;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1000;

    /* Ensure the scrollbar appears when content overflows */
    overflow-y: auto;
    scrollbar-gutter: stable; /* Keeps the scrollbar gutter visible */
  }

  #SideNav > .section-inner {
    justify-content: space-between;
    max-height: 70rem;
  }

  .SideNavUl {
    height: 25.4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    list-style: none;
    padding: 0;
  }

  /* Add scrollbar styling */
  .SideNav::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }

  .SideNav::-webkit-scrollbar-thumb {
    background-color: darkgray; /* Color of the scrollbar thumb */
    border-radius: 6px;
  }

  .SideNav::-webkit-scrollbar-thumb:hover {
    background-color: pink; /* Change thumb color on hover */
  }

  .SideNav::-webkit-scrollbar-track {
    background: transparent; /* Background of the scrollbar track */
  }
}
