@import "./GlobalColors.scss";

#WEBDESIGNDEVELOPMENT, #USEREXPERIENCE, #ADOBEILLUSTRATOR {
  min-width: 300px !important;
  justify-content: center;
}

#WEBDESIGNDEVELOPMENT .section-inner, #USEREXPERIENCE .section-inner, #ADOBEILLUSTRATOR .section-inner {
  align-items: center !important;
  max-height: 55rem;
}

.slick-arrow {
  margin-top: 22rem;
}

@media (min-width: 1px) and (max-width: 767px) {
  // .portfolio-projects {
  //   display: none;
  // }
}

.H2sProjects .m-herr-h2 {
  height: 5rem;
}

.portfolio-projects .section .H2sProjects .m-cinzel-h2 {
  font-size: 2rem;
}

.portfolio-projects .section .H2sProjects {
  width: 100%;
  height: 6.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3.12rem;
}

.portfolio-projects .section .slick-slider {
  height: 100%;
  width: 100%;
  max-width: 25rem;
}

.portfolio-projects .section .slick-slider .slick-arrow {
  z-index: 1;
  margin-top: unset;
  height: 1.54rem;
  width: 1.75rem;
  border-radius: 25px;
  transition: 0.15s ease-in-out;
}

.portfolio-projects .section .slick-slider .slick-prev {
  left: 1rem;
  bottom: -4rem;
  top: unset;
  box-shadow: 5px 5px 10px 0px #a6abbd;
}

.portfolio-projects .section .slick-slider .slick-next {
  right: 1rem;
  bottom: -4rem;
  top: unset;
  box-shadow: 5px 5px 10px 0px #a6abbd;
}

.portfolio-projects .section .slick-slider .slick-dots li {
  background-color: $color-silver;
  border-radius: 50%;
  height: 10px;
  margin-right: 10px;
  width: 10px;
}

.portfolio-projects .section .slick-slider .slick-dots {
  bottom: unset;
  margin-top: 2rem;
}

.portfolio-projects .section .slick-slider .slick-dots .slick-active {
  background-color: $color-hotpink;
  width: 10px;
  height: 10px;
  margin-right: 10px;
  padding: 0;
  cursor: pointer;
  border-radius: 50%;
}

#projects {
  padding: 3.25rem;
}

// This may cause issues in the overlay that pops up in triedcircles
.project-card .active {
  height: 100%;
}

.portfolio-projects .section {
  padding: 6.5rem 1.5rem 9.5rem 1.5rem;
  overflow: hidden;
}



.slick-prev:before,
.slick-next:before {
  font-size: 1.8rem !important;
  color: $color-hotpink;
  opacity: 100%;
  transition: 0.15s ease-in-out;
}


.slick-arrow:hover{
  background: $color-pale-dogwood;
}

/* Override slick-track transition */
.portfolio-projects .section .slick-slider .slick-list .slick-track {
  transition: none !important;
}

/* Custom slide transition */
.portfolio-projects .section .slick-slider .slick-list .slick-track {
  transition: transform 1s ease-in-out !important; /* Slide in 1 second with smooth ease-in-out effect */
}

/* Project Card Active Transition */
.portfolio-projects .section .slick-slider .slick-slide.slick-active {
  transition: transform 0.8s ease, opacity 0.8s ease !important; /* Same duration and ease for both */
  transform: translateX(0); /* Slide into place */
  opacity: 1; /* Fade in */
}

/* Overlay Fade-in Transition */
.portfolio-projects .section .overlay {
  transition: opacity 0.2s ease-in-out !important; /* Adjusted timing for smoother transition */
  opacity: 1; /* Ensure it stays visible when active */
}

.portfolio-projects .section .slick-slide.slick-active .overlay {
  opacity: 1; /* Keep overlay visible for active slide */
}

.portfolio-projects .section .slick-slider .slick-slide {
  transition: transform 0.3s ease, opacity 0.3s ease; /* Smooth transition */
  opacity: 1; /* Ensure all slides are visible */
}

.portfolio-projects .section .slick-slider .slick-cloned {
  opacity: 1; /* Cloned slides should behave like originals */
  transform: translateX(0); /* Keep cloned slides in the same flow */
}

.slick-slider ul .slick-dots {
  min-width: unset !important;
}

// -----------------------------
// T A B L E T  S T Y L E S
@media (min-width: 768px) {

  #WEBDESIGNDEVELOPMENT, #USEREXPERIENCE, #ADOBEILLUSTRATOR {
  min-height: 1000px !important;}

  .portfolio-projects .section .H2sProjects .m-cinzel-h2 {
    font-size: 2.2rem;
  }

  .portfolio-projects .section {
    padding: 5.25rem 3.25rem 9rem 3.25rem;
  }

  .portfolio-projects .section .slick-slider {
    width: 54vw;
  }
  .portfolio-projects .section .slick-slider .slick-prev {
    left: 3rem;
  }

  .portfolio-projects .section .slick-slider .slick-next {
    right: 3rem;
  }

  .slick-next,
  .slick-prev {
    top: 50%;
  }

  .portfolio-projects .section .H2sProjects {
    height: 6.5rem;
  }

  .H2sProjects .m-herr-h2{
    font-size: 4.2rem !important;
    display: flex;
    align-items: flex-end;
  }

  .H2sProjects .m-cinzel-h2  {
    font-size: 2rem !important;
    display: flex;
    align-items: flex-start;
  }
}

// -----------------------------
// D E S K T O P  S T Y L E S
@media (min-width: 1024px) {
  // .portfolio-projects .section .slick-slider .slick-prev {
  //   left: 3rem;
  // }

  // .portfolio-projects .section .slick-slider .slick-next {
  //   right: 3rem;
  // }
}


// -----------------------------
// L A R G E  M O N I T O R  S T Y L E S
@media (min-width: 1200px) {
.portfolio-projects .section .slick-slider {
  max-width: 90rem !important;
  width: 100%;
}

.portfolio-projects .section .slick-slider .slick-list .slick-track {
  display: flex;
  gap: 30px;
}

.portfolio-projects .section .slick-slider .slick-prev {
  left: 18.7rem;
}

.portfolio-projects .section .slick-slider .slick-next {
  right: 18.7rem;
}
}



// SPIRIT OF WICK COMMENTED OUT

.slick-slide:nth-child(4) div .project-card .overlay .projectCardTextAndButtons .buttons a:nth-child(1) {
  display: none;
}


// -----------------------------
// S U P E R  L A R G E  M O N I T O R  S T Y L E S
@media (min-width: 1200px) {  
  .portfolio-projects .section .slick-slider .slick-prev {
    left: 24rem;
  }
  
  .portfolio-projects .section .slick-slider .slick-next {
    right: 24rem;
  }
  }


//   // SHORT SCREEN
// @media (min-width: 1500px) and (max-height: 999px) {
//   .portfolio-projects .section .slick-slider .slick-prev {
//     left: -5rem;
//     bottom: 18rem;
//   }
  
//   .portfolio-projects .section .slick-slider .slick-next {
//     right: -5rem;
//     bottom: 18rem;
//   }
// }

.slick-dots li.slick-active button::before {
  display: none !important;
}

.slick-dots li button::before {
  display: none !important;
}