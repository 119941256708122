@import "./GlobalColors.scss";

.Footer {
    height: 17.3125rem;
min-width: 300px !important;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, $color-white, $color-pale-dogwood);
    color: $color-rosy-brown;
}

.footerText {
    margin-top: 3rem;
    margin-bottom: 1.8rem;
    // text-shadow: 2px 2px 5px rgba(255, 255, 255, 1);
}

.socialMediaIcon {
    width: 1.5rem;
}

.foooter-icons {
    display: flex;
    width: 9rem;
    align-items: center;
    justify-content: space-between;

    a {
        display: flex;
        border: 1px solid white;
        height: 2rem;
        width: 2rem;
        align-items: center;
        justify-content: center;
        padding: 1.5rem;
        border-radius: 50%;
        box-shadow: 5px 5px 10px 0px darkgray;
        transition: 0.15s ease-in-out;
        cursor: pointer;
    }

    a:active, a:hover {
        background-color: $color-hotpink;
    }
}


.Footer {
    height: 45vh; /* Adjust the height to 30vh */
    scroll-snap-align: end; /* Align the footer at the end of the scroll */
  }
  

// -----------------------------
// T A B L E T  S T Y L E S
@media (min-width: 768px) {
    .Footer {
    height: 30vh !important;
    }
    .footerText {
        margin-bottom: unset;
        margin-top: 7rem;
    }

    .foooter-icons {
        display: none;
    }
}

// -----------------------------
// D E S K T O P  S T Y L E S
@media (min-width: 1024px) {}

