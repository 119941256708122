.section {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 800px !important;
  height: 100vh; // Maintain full viewport height
  max-height: 932px !important;
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  padding: 1.25rem 1.5rem;
}

.section-inner {
  flex-grow: 1; // Ensures inner content takes the remaining height
  display: flex;
  justify-content: center;
  flex-direction: column;
}

// -----------------------------
// T A B L E T  S T Y L E S
@media (min-width: 768px) {
  .section {
    width: unset !important;
    max-height: 1366px !important;
  }
}

// -----------------------------
// D E S K T O P  S T Y L E S
@media (min-width: 1024px) {
  .section {
    // min-height: 750px !important;
    max-height: 1440px !important;
  }
}


// -----------------------------
// L A R G E  M O N I T O R  S T Y L E S
@media (min-width: 1200px) {
  .section {
    max-height: 2160px !important;
  }
}
