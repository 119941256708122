@import "./GlobalColors.scss";

#ABOUT {
  min-width: 300px !important;
  // max-height: 1850px !important;
}

@media (min-width: 1px) and (max-width: 767px) {
  .scroll-indicator {
    bottom: 7rem;
    color: $color-hotpink !important;
  }
}

.AboutH2s- {
  max-width: 30rem;
  width: 85%;
}

.About {
  // overflow: hidden;
  .section {
    position: relative;
  }

  .section-inner {
    height: 100%;
    align-items: center;
  }

  .slider-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    padding: 0; /* Remove padding specifically for the ImageSlider */
  }

  .image-slider {
    height: 100%;
    width: 100%;
  }

  .additional-content {
    position: absolute;
    width: 100%;
    z-index: 1; /* Ensure content appears above the slider */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 14rem;
  }
}
.aboutBody {
  height: 26rem;
  overflow-y: auto; /* Enable vertical scrolling */
  width: 100%; /* Ensure it takes the full width of the container */
  box-sizing: border-box; /* Include padding in the element’s width */
  padding-right: 0.7rem; /* Prevent content from touching the scrollbar */
  position: relative; /* For positioning the pseudo-element */
  margin-top: 2.31rem;
  max-width: 30rem;
}

.gradient-overlay {
  position: fixed; /* Fixed position so it stays at the bottom */
  bottom: 60px;
  left: 0;
  width: 100%;
  height: 5rem; /* Increased height for more evident gradient effect */
  background: linear-gradient(
    to bottom,
    transparent,
    rgba(255, 255, 255, 1)
  ); /* More visible gradient */
  pointer-events: none; /* Ensure it doesn’t interfere with scrolling */
  z-index: 2; /* Ensure it appears above other content */
}

.About {
  .section {
    position: relative;
  }

  .section-inner {
    height: 100%;
  }

  .slider-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    padding: 0;
  }

  .additional-content {
    position: absolute;
    width: 100%;
    z-index: 1;
  }
}

.currentContainer {
  display: flex;
  justify-content: center;
  position: relative;
  overflow-y: auto;
  width: 100%; /* Full width */
  height: 32rem;
  padding: 0rem 1.5rem;
}

.gradientRectangleAndScrollArrow {
  z-index: 1;
  position: absolute;
  width: 100%; /* Ensure it takes up the full viewport width */
  left: 0;
  height: 18rem; /* Adjust height as needed */
  bottom: 0; /* Stick it to the bottom of the current container */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.gradientRectangle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0.39%, #fff 50%);
  z-index: 0;
}

.scroll-indicator {
  position: relative;
  z-index: 1;
  font-size: 1rem;
  text-align: center;
  color: $color-silver;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

// -----------------------------
// T A B L E T  S T Y L E S
@media (min-width: 768px) {
#ABOUT {
  min-height: 1000px !important;
}

  .About .additional-content {
    bottom: 0;
    width: 100%;
    z-index: 1;
    padding: 0;
    display: unset;
  }

  .AboutH2s- {
    padding: 0rem 3.25rem;
    max-width: unset;
  }

  .additional-content .m-herr-h2 {
    margin-bottom: 1.88rem;
    width: 100%;
  }

  .aboutBody {
    margin-top: 1.88rem;
    padding: 0rem;
    max-width: unset;
  }

  .About .section {
    padding: 9.25rem 0rem;
  }

  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    10% {
      transform: translateY(-10px); /* Bounce height */
    }
    20% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(0); /* End state */
    }
  }

  .scroll-indicator {
    text-align: center;
    position: absolute;
    bottom: 4rem;
    padding: 2rem;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: end;
    justify-content: center;
    left: 0;
    right: 0;
    font-size: 0.9rem;
    color: $color-silver;
    z-index: 1;
    transition: 0.15s ease-in-out;
    animation: bounce 5s ease-in-out infinite; /* Full duration of 5 seconds */
  }

  .scroll-indicator:hover {
    color: $color-hotpink;
  }

  .currentContainer {
    padding: 0rem 3.25rem;
  }
}

// -----------------------------
// D E S K T O P  S T Y L E S
@media (min-width: 1024px) {
}



// -----------------------------
// S U P E R  L A R G E  S T Y L E S
@media (min-width: 1200px) {
  .additional-content {
    max-width: 60rem;
  }
}



// -----------------------------
// S U P E R  E X T R A  L A R G E  S T Y L E S
@media (min-width: 1700px) {
  .additional-content {
    max-width: unset;
    width: 1410px;
  }
  .currentContainer {
    width: 60rem;
    padding: 0rem 5rem;

  }
  .gradientRectangleAndScrollArrow {
    display: none;
    width: 910px;
  }

  .AboutH2s- {
    padding: 0rem 5rem;
  }

 
  // .scroll-indicator {
  //   width: 100rem;
  // }

}
