@import "./GlobalColors.scss";

#TESTIMONIALS {
  align-items: center;
  min-width: 300px !important;
  .section-inner {
    max-width: 25rem;
  }
}


.testimonialsContainer .slick-prev {
  left: 28px !important;
  top: unset;
  bottom: -1rem;
  display: none;

}

.testimonialsContainer .slick-next {
  right: 28px !important;
  top: unset;
  bottom: -1rem;
  display: none;
}


.social-media-links a {
  cursor: pointer;
}

.testimonials-section {
  position: relative; /* Establish this section as a positioned ancestor */
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Prevent overflow outside the section */
  background-color: #fff;
  box-sizing: border-box;
  scroll-snap-align: start;
  min-height: 800px;
max-height: 932px !important;

}

.testimonials-scroll-container {
  flex-grow: 1;
  display: flex;
  align-items: center;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  .testimonial-card {
    flex: 0 0 100%;
    scroll-snap-align: center;
    box-sizing: border-box;
    transition: transform 0.3s ease-in-out;
  }
}

.slider-dots {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;

  .dot {
    width: 15px;
    height: 15px;
    // margin: 0 5px;
    border-radius: 50%;
    background-color: $color-silver;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &.active {
      background-color: $color-hotpink;
    }
  }
}

.dot:hover {
  background-color: $color-pale-dogwood !important;
}

.testimonialsContainer {
  position: relative;

  background: rgb(255, 255, 255, 0.35);
}

.testimonials-background-text {
  position: absolute;
  right: -14vw; /* Align to the right edge of the .testimonials-section */
  top: 50%; /* Adjust the vertical position */
  transform: rotate(270deg); /* Rotate the text */
  white-space: nowrap;
  pointer-events: none;
  height: 0px; /* Adjust height if needed */
  color: $color-rosy-brown;
}

.TestimonialsSection {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  padding: 30px;
  box-sizing: border-box;
  position: relative; /* Ensure the section is positioned relative */
}

.section-inner {
  flex-grow: 1; /* Ensures inner content takes the remaining height */
  display: flex;
  flex-direction: column;
  width: 100%;
}

// T E S T I N G  T H I S
.social-media-links {
  margin-top: 1rem;
  display: flex;
  justify-content: center;

  .social-link {
    margin: 0 0.5rem;
    transition: transform 0.2s ease-in-out;
    background: #C8C6C6;
    padding: .4rem .4rem .5rem .4rem;
    border-radius: 27px;
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    &:hover {
      transform: scale(1.1);
    }

    .socialMediaIcon {
      width: 20px;
      height: 20px;
    }
  }
}

// -----------------------------
// T A B L E T  S T Y L E S
@media (min-width: 768px) {
.testimonials-section, #TESTIMONIALS {
  min-height: 1000px !important;
  max-height: unset;
}

  .testimonialsContainer {
    transform: unset;
    padding: unset !important;
  }

  .testimonialsContainer .slick-prev {
    left: 100px !important;

  }
  
  .testimonialsContainer .slick-next {
    right: 100px !important;

  }

  .testimonials-background-text {
    right: -16vw;
  }

  .testimonials-section .section {
    padding: 0rem 3.25rem;
  }

  .testimonials-scroll-container {
    max-height: unset;
  }

  .testimonial-card {
    padding: 0px 30px 30px 30px;
  }


    .social-link {

      height: 3rem;
      width: 3rem;

  
      .socialMediaIcon {
        width: 24px;
        height: 24px;
      }
    
  }
}

// -----------------------------
// D E S K T O P  S T Y L E S
@media (min-width: 1024px) {

  .testimonial-card .m-body {
    font-size: 1rem !important;
  }

  .testimonials-section {
    // min-height: 800px !important;
    max-height: 2160px !important;
  }
  .testimonials-background-text {
    right: -15vw;
  }
}

.testimonial-card {
  opacity: 0.3;
  transform: scale(0.98);
  transition: opacity 0.6s cubic-bezier(0.25, 1, 0.5, 1),
    transform 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}

.testimonials-scroll-container .testimonial-card.active {
  opacity: 1;
  transform: scale(1);
}

.slider-dots {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .dot {
    // Reset button styles to avoid deformation
    background: none;
    border: none;
    padding: 0;
    outline: none;
    appearance: none;

    width: 10px;
    height: 10px;
    // margin: 0 5px;
    border-radius: 50%;
    background-color: $color-silver;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &.active {
      background-color: $color-hotpink;
    }
  }
}

// -----------------------------
// S U P E R  L A R G E  S T Y L E S
@media (min-width: 1200px) {
  #TESTIMONIALS .section-inner {
    max-width: 30rem;
  }
  .testimonials-background-text {
    right: -10vw;
  }
}

// -----------------------------
// S U P E R  E X T R A  L A R G E  S T Y L E S
@media (min-width: 1500px) {
  #TESTIMONIALS .section-inner {
    max-width: 40rem;
  }
  .testimonials-background-text {
    right: -5vw;
  }

  .testimonialsContainer .slick-prev {
    left: 200px !important;

  }
  
  .testimonialsContainer .slick-next {
    right: 200px !important;

  }
}




// TESTING
// .slick-prev,
// .slick-next {
//   display: block;
//   width: 30px;
//   height: 30px;
//   background-color: rgba(0, 0, 0, 0.5); // Adjust color and size as needed
//   border-radius: 50%;
//   position: absolute;
//   top: 50%;
//   transform: translateY(-50%);
//   cursor: pointer;
//   z-index: 1;
// }

// .slick-prev {
//   left: 10px; // Position for left arrow
// }

// .slick-next {
//   right: 10px; // Position for right arrow
// }

// .slick-prev::before,
// .slick-next::before {
//   content: "";
//   display: inline-block;
//   width: 10px;
//   height: 10px;
//   border-top: 2px solid white;
//   border-right: 2px solid white;
//   transform: rotate(45deg);
//   margin: 0 auto;
// }

// .slick-prev::before {
//   transform: rotate(-135deg);
// }

// .slick-next::before {
//   transform: rotate(45deg);
// }
