@import "../src/styles/GlobalColors.scss";

html {
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow-x: hidden;
  box-sizing: border-box;
}

* {
  box-sizing: inherit;
  // outline: 1px solid red;
}

// Global Hover Stuff
.SideNavUl li,
.sideNavMyName,
.EdButton,
.openLinks .m-caption-extra-light,
.openLinks .m-caption-regular,
.seeMore,
.contactButton {
  cursor: pointer;
  transition: 0.15s ease-in-out;
}

.openLinks .m-caption-extra-light:hover,
.openLinks .m-caption-regular:hover,
.seeMore:hover {
  color: $color-hotpink;
}

.EdButton:hover,
.contactButton:hover,
.project-card button:hover {
  background-color: $color-hotpink !important;
}

// -----------------------

.indexDivSmall {
  overflow-x: scroll;
}

// -----------------------------
// T A B L E T  S T Y L E S
@media (min-width: 768px) {
  .indexDiv {
    margin-left: 15.6rem;
  }
  .indexDivSmall, .indexDivShort {
    display: none;
  }
}

// -----------------------------
// D E S K T O P  S T Y L E S
@media (min-width: 1024px) {
}