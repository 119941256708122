/* src/ScrollableContainer.scss */
.scrollable-container {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100vh; /* Ensure it covers the viewport height */
  scroll-behavior: smooth; // this is new
}

/* src/Section.scss */
.section {
  scroll-snap-align: start;
}

// -----------------------------
// T A B L E T  S T Y L E S
@media (min-width: 768px) {
  .section {
  }
}

// -----------------------------
// D E S K T O P  S T Y L E S
@media (min-width: 1024px) {
}


// SHORT SCREEN
@media (min-width: 768px) and (max-height: 999px) {
  .scrollable-container {
    scroll-snap-type: none; /* Disables snapping for short screens */
  }

  .section {
    scroll-snap-align: unset; /* Removes alignment for each section */
  }
}


// SHORT SCREEN
@media (min-width: 1px) and (max-width: 767px) {
  .scrollable-container {
    scroll-snap-type: none; /* Disables snapping for short screens */
  }

  .section {
    scroll-snap-align: unset; /* Removes alignment for each section */
  }
}