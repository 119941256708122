@import "./GlobalColors.scss";

#CONTACT {
  align-items: center;
  min-width: 300px !important;

  .section-inner {
    max-width: 25rem;
  }
}

.contactSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottom {
  display: flex;
  justify-content: flex-end;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
}

.inputBox {
  display: flex;
  height: 3.75rem;
  padding: 0.4375rem 0.8125rem;
  align-items: center;
  width: 100%;
  border-radius: 0 !important;
}

.inputBoxIndividual {
  border-radius: 8px;
  // background-color: #FFF3F9;
  padding: 10px;
  font-size: 1rem !important;
}

input,
textarea {
  border: none;
  border-bottom: 1px solid $color-rosy-brown;
  width: 100%;
}

input::placeholder,
textarea::placeholder {
  opacity: 0.6; /* Adjust this value to control the opacity */
}

button {
  border: none;
  background-color: $color-rosy-brown;
  color: $color-white;
  // width: 10rem;
  height: 2.3rem;
  border-radius: 4px;
}

input:focus-visible,
textarea:focus-visible {
  outline: none;
  border-bottom: 1px solid $color-hotpink;
}

input,
textarea {
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.error-message {
  color: hotpink;
}

.inputBox:nth-child(3){
  height: unset;

}
.MessageLastChild {
  width: 100%; /* Full width of the container */
  height: 120px; /* Larger height for the message box */
  padding: 10px;
  // background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFF3F9 100%);

}



// -----------------------------
// T A B L E T  S T Y L E S
@media (min-width: 768px) {
  #CONTACT {
    min-height: 1000px !important;
  }
  
  .contactSection .H2group {
    margin-top: 0;
    height: 9.06rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 18.4rem;
  }

  .H2group .top {
    width: 100%;
    text-align: left;
  }

  .H2group .bottom {
    width: 100%;
    text-align: left;
  }

  .Contact .section {
    padding: 0rem 3.25rem;
  }

  .contactSection form {
    max-width: 30rem;
  }

  .contactSection .H2group {
    margin-bottom: 3.75rem;
  }

  button {
    height: 2.75rem;
  }

  .successfullySubmitted {
    position: absolute;
    bottom: 3.5rem;
  }
}

// -----------------------------
// D E S K T O P  S T Y L E S
@media (min-width: 1024px) {
  .EdButton {
    height: 3rem;
  }

  .H2groupEducation {
    margin-bottom: 2.44rem;
  }
}


// -----------------------------
// S U P E R  L A R G E  S T Y L E S
@media (min-width: 1200px) {
  .EdButton {
    height: 3.3rem;
  }
}



// _________________
.loading-animation {
  p {
    margin-top: 0.5rem;
    color: $color-rosy-brown;
  }
}

.success-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  animation: fadeIn 0.5s ease-in-out;

  .checkmarkIcon {
    height: 40px;
  }

  p {
    color: $color-hotpink;
    margin-top: 0.5rem;
  }
}

// Keyframes for loading spinner and fade-in animation
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// Global overlay style
.confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7); // Dark transparent background
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; // Ensure it stays on top of everything
}

// Content inside the overlay
.overlay-content {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  position: relative;
}

.checkmarkIcon {
  height: 50px;
  margin-bottom: 1rem;
}

.overlay-content p {
  color: $color-hotpink;
  font-size: 1.5rem;
  margin-top: 1rem;
}

// Close button style
.closeIcon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  height: 24px;
}

// Prevent background scrolling when overlay is visible
body.overlay-active {
  overflow: hidden;
}

// TESTING

.funds-success-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Dark transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Ensure it appears above other content */
  padding: 1.5rem;
}

.funds-checkmark-container img {
  height: 8rem;
}

.funds-success-message-container {
  font-family: Roboto, "Segoe UI", "Helvetica Neue", sans-serif !important;
  color: #333;
  max-width: 500px;
  margin: 0 auto;
  background: white; /* White background for message */
  padding: 20px;
  border-radius: 8px; /* Rounded corners */
  position: relative;
}

.funds-success-message p {
  color: $color-black;
}

.funds-success-message p a {
  text-decoration: none;
  color: dodgerblue;
  :hover {
    cursor: pointer;
  }
}

.close-overlay {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333; /* Change color as needed */
}

/* Add your existing styles for the checkmark and other components below */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it's on top */
}

.funds-success-message-container {
  font-family: Roboto, "Segoe UI", "Helvetica Neue", sans-serif !important;
  color: $color-black; /* White text for contrast */
  text-align: center;
  background: $color-white; /* Optional background for the message box */
  padding: 20px;
  border-radius: 8px;
}

.funds-success-done-text {
  color: $color-black;
}

.close-overlay {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: $color-black;
  font-size: 24px;
  cursor: pointer;
}

//  ------- TESTING

.loading-animation {
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeIn 0.5s ease-in-out;

  .loader {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid $color-white; // Use your defined color variable
    border-radius: 50%;
    width: 15px; // Size of the loader
    height: 15px; // Size of the loader
    animation: spin 1s linear infinite; // Spin animation
  }

  p {
    margin-top: 0.5rem;
    color: $color-rosy-brown; // Text color
  }
}

// Keyframes for loading spinner and fade-in animation
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.submit-button {
  margin-top: 2rem;
  padding: 1rem 4rem;
  height: unset !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: auto;
  min-width: 10rem;
  box-shadow: 5px 5px 10px 0px #a6abbd;
  transition: 0.15s ease-in-out;
  cursor: pointer;

}

.loading-animation {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.3); 
  border-top: 4px solid $color-white;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.submit-button:active,
.submit-button:hover {
  background-color: $color-hotpink !important;
}

button.loading {
  background-color: $color-hotpink;
}


// ---

