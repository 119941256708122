@import "./GlobalColors.scss";
@import "./GlobalFonts.scss";

.welcome-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: black; /* Set your desired background color */
  font-family: 'Cinzel', "Nunito Sans", sans-serif !important;
  font-size: 5rem;
}

.welcome-letter {
  font-family: "Ballet", cursive !important; /* Ensure the variable is correctly set in GlobalFonts.scss */
  color: white; /* You can set the color here as well */
  transition: opacity 0.5s ease;
  text-shadow: 0px 0px 17px darkgray; /* Add text shadow */
}

.bienvenido {
  font-size: 10vh; /* Responsive font size */
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}

.letra {
  opacity: 0; /* Initially hidden */
  animation: aparecer 0.5s forwards; /* Animation to appear */
  animation-delay: calc(0.06s * var(--i)); /* Individual delay for each letter */
}

@keyframes aparecer {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

span {
  transition: opacity 0.5s ease;
}

// -----------------------------
// T A B L E T  S T Y L E S
@media (min-width: 768px) {
  .welcome-screen {
    font-size: 8rem;
  }
}


// testingtesting

@keyframes backgroundTransition {
  0% { background-color: #fff; } /* Pink */
  100% { background-color: #000; } /* Deep Pink */
}

.welcome-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white;
  animation: backgroundTransition 2s forwards; // Set to 3s for a faster transition
}
