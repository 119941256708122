@import "./GlobalColors.scss";

.project-card {
  position: relative;
  width: 100%;
  background-size: cover;
  overflow: hidden;
  transition: opacity 0.2s ease-in-out; /* Smooth transition for the card itself */
}

.project-card .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 1.5rem;
  box-sizing: border-box;
  opacity: 1;
  transition: opacity 0.2s ease-in-out; /* Smooth transition for the overlay */
  overflow-y: hidden;
}

.project-card .overlay.fade-out {
  opacity: 0;
}

.project-card .overlay.fade-in {
  opacity: 1;
}

.project-card h2 {
  margin: 0;
}

.project-card p {
  margin: 10px 0;
}

.project-card .buttons {
  display: flex;
  justify-content: space-between;
}

.project-card button {
  background: $color-rosy-brown;
  border: none;
  color: #fff;
  cursor: pointer;
  transition: background 0.3s;
  flex-grow: 1;
  margin-right: 10px;
}

.project-card button:last-child {
  margin-right: 0px;
}

.project-card button:hover {
  background: #1e7bbf;
}

.portfolio-projects .section .slick-slider .slick-list {
  height: 100%;
  .slick-track {
    height: 100%;
    .slick-slide {
      height: 100%;
      div {
        height: 100% !important;
        max-width: 400px;
        max-height: 522px;
        .project-card .active {
          height: 100% !important;
          .overlay .fade-in {
            .buttons {
              height: 2.75rem !important;
            }
          }
        }
      }
    }
  }
}

.projectCardTextAndButtons {
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
}

.projectCardTextAndButtons .buttons .m-body {
  font-size: 0.7rem;
}

.buttons > a {
  display: flex;
  height: 2.3rem; //contact
  border-radius: 4px; //contact
  background: #c3a49c; //project card
  border: none; //project card
  color: #fff; //project card
  cursor: pointer; //project card
  transition: background 0.3s; //project card
  flex-grow: 1; //project card
  margin-right: 10px;
  text-decoration: none;
}

.buttons > a:last-child {
  margin-right: 0px; //project card
}

// -----------------------------
// T A B L E T  S T Y L E S
@media (min-width: 768px) {
  .project-card .overlay {
    padding: 2rem;
  }

  .project-card .buttons {
  }

  .portfolio-projects .section .slick-slider .slick-list {
    .slick-track {
      .slick-slide {
        div {
          max-width: 400px !important;
          max-height: 726px !important;
        }
      }
    }
  }
}

// -----------------------------
// D E S K T O P  S T Y L E S
@media (min-width: 1024px) {
  
}

// -----------------------------
// L A R G E  M O N I T O R  S T Y L E S
@media (min-width: 1200px) {
  .project-card {
    // width: 50%;
  }

  .portfolio-projects .section .slick-slider .slick-list {
    .slick-track {
      .slick-slide {
        div {
          max-width: 533px !important;
          max-height: 726px !important;
        }
      }
    }
  }
}

@media (min-width: 1500px) {
  .portfolio-projects .section .slick-slider .slick-list {
    .slick-track {
      .slick-slide {
        div {
          max-width: 418px !important;
          max-height: 726px !important;
        }
      }
    }
  }
}
