@import "./GlobalColors.scss";

.testimonial-card {
  text-align: center;
  transition: transform 0.3s ease-in-out;

  .testimonial-photo {
    // width: 80px;
    height: 6.875rem;
    border-radius: 50%;
    margin-bottom: 1.44rem;
  }

  .testimonial-name {
    font-size: 18px;
    font-weight: 600;
  }

  .testimonial-text {
    font-size: 14px;
    color: #555;
  }
}

.testimonialName {
  color: $color-fairy-tale;
  margin: 0 0 0.81rem 0;
}

// -----------------------------
// T A B L E T  S T Y L E S
@media (min-width: 768px) {
  .testimonial-card {
    padding: 20px;
  }
}

// -----------------------------
// D E S K T O P  S T Y L E S
@media (min-width: 1024px) {
}


//  T E S T I N G  T H I S
.socialMediaIcon {
  width: 24px; // Adjust as needed
  height: 24px; // Adjust as needed
  display: inline-block; // Ensure it's displayed
}
